.dixa-messenger-namespace .dixa-messenger-wrapper {
  z-index: 149 !important;
}

/**
Design Mollie Components
*/
.mollie-components label {
  opacity: 0.6;
  padding-bottom: 7px;
  padding-top: 13px;
  font-weight: 500;
  display: block;
}

.mollie-components .mollie-component {
  background: #fff;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1),
    0px 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 13px;
  border: 1px solid transparent;
  transition: 0.15s border-color cubic-bezier(0.4, 0, 0.2, 1);
  font-weight: 500;
}

.mollie-components .mollie-component.has-focus {
  border-color: #0077ff;
  transition: 0.3s border-color cubic-bezier(0.4, 0, 0.2, 1);
}

.mollie-components .mollie-component.is-invalid {
  border-color: #ff1717;
  transition: 0.3s border-color cubic-bezier(0.4, 0, 0.2, 1);
}

.mollie-components .component-error {
  font-size: 12px;
  margin-top: 2px;
  color: #ff1717;
  font-weight: 400;
}

/**
Apple Pay Button
*/
.apple-pay-button {
  height: 48px;
  max-width: 100%;
  width: 100%;
  font-size: 16px;
  display: inline-block;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: buy; /* Use any supported button type. */
}
.apple-pay-button-black {
  -apple-pay-button-style: black;
}
.apple-pay-button-white {
  -apple-pay-button-style: white;
}
.apple-pay-button-white-with-line {
  -apple-pay-button-style: white-outline;
}
